import * as types from '../constants';
import getApiData from './api-data';
import sentryLogger from '../services/sentry';
import { getCompleteProjects } from './freelancer-overview-tab';
import { showHideWarning } from './warning-bar';
import {
  ACTIVE_PROJECT_PAGINATION_KEY,
  ACTIVE_PROJECT_SORTING_KEY,
  clearUserAuthData,
  client,
  CONCEPT_BRIEFINGS_PAGINATION_KEY,
  FINISHED_PROJECT_PAGINATION_KEY,
  FINISHED_PROJECT_SORTING_KEY,
  INACTIVE_USER_STATES,
  logoutCrowdin,
  quest,
  setUserAuthData,
  USER_ROLES,
  UserRoles,
} from '../utils';

import { redirectToDeactivatedProfilePage } from '../utils/helpers/redirectHelpers';
import { getProjectTypes } from './api-data/project-types';

const shouldRedirect = (array = []) => (
  array.some(item => item.test(window.location.pathname))
);

export const setImpersonate = (who) => ({
  type: types.IMPERSONATE,
  payload: who,
});

export const setImpersonatedData = (userData) => ({
  type: types.SET_IMPERSONATED_DATA,
  payload: userData,
});

export const getImpersonateData = (id, isCompany = false) => (
  (dispatch) => {
    const url = `/impersonate/${id}`;

    return client.post(url)
      .then(({ success, result }) => {
        if (!success) {
          throw new Error('Impersonate Error');
        }

        const impersonatedData = {
          id: result.id,
          name: isCompany
            ? result.client?.company ?? ''
            : result.name,
          avatar: isCompany
            ? result.client?.avatar ?? ''
            : result.avatar,
          type: result.type,
          client_id: result.client_id || null,
        };
        dispatch(setImpersonatedData(impersonatedData));

        return result;
      })
      .catch(error => {
        throw error.data;
      });
  });

const restoreImpersonateIfExist = () => (
  async (dispatch) => {
    const impersonatedId = localStorage.getItem('impersonate_id');
    const isImpersonated = !!impersonatedId;
    const impersonatedType = localStorage.getItem('type');
    const isCompany = localStorage.getItem('impersonate_is_company') === 'true';

    const shouldRestoreImpersonate = isImpersonated && !!impersonatedType;
    if (shouldRestoreImpersonate) {
      return dispatch(getImpersonateData(impersonatedId, isCompany));
    }
  }
);

export const receiveAuth = () => (
  async (dispatch, getState) => {
    const { user } = getState().auth;

    // we use token from client instance
    // check if token missing. If true - return
    if (!client.defaults.headers.common.Authorization) {
      return;
    }

    const url = '/getProfile';

    return client
      .get(url)
      .then(async ({ data: { result } }) => {
        dispatch({
          type: types.RECEIVE_PROFILE_SUCCESS,
          payload: result,
        });

        try {
          // we need to wait until impersonation finished before auth action will be done
          await dispatch(restoreImpersonateIfExist());
        } catch {
          // impersonation failed
        }

        return result;
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });
        return Promise.reject(error);
      });
  }
);

export const refreshAccessToken = () => {
  const refreshToken = localStorage.getItem('refresh_token');
  return client.post('/refreshToken', { refresh_token: refreshToken })
    .then(({ data: { result } }) => result);
};

export function login(email, password, lang) {
  return dispatch => {
    const url = '/login';

    return quest.post(url, { email, password })
      .then(({ data: { result } }) => {
        if (!result.access_token) {
          throw new Error('Token has not been provided');
        }

        setUserAuthData(result);

        return dispatch(receiveAuth())
          .then(({ user }) => {
            const isFreelancer = [UserRoles.WRITER, UserRoles.DESIGNER].includes(user.type);
            isFreelancer && dispatch(getCompleteProjects(user.id));

            dispatch(getApiData());
          });
      })
      .catch((error) => {
        const { data: { data: { user } } } = error;
        // TODO there must be a condition
        sentryLogger.exceptionWithScope(error, { url });

        dispatch({
          type: types.LOGIN_FAILURE,
          payload: 'Invalid email or password',
        });

        clearUserAuthData();

        const userState = user?.state;
        const isInactiveState = INACTIVE_USER_STATES.includes(userState);

        const isClient = user?.type === UserRoles.CLIENT;
        const isClientInactiveState = INACTIVE_USER_STATES.includes(user?.client?.state);
        const isClientInactive = isClient && isClientInactiveState;

        const shouldShowDeactivatedPage = isInactiveState || isClientInactive;
        shouldShowDeactivatedPage && redirectToDeactivatedProfilePage(lang, user);
      });
  };
}

export function logout() {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = '/logout';

    client.post(url)
      .then(() => {
        dispatch({
          type: types.LOGOUT_SUCCESS,
        });

        clearUserAuthData(true);

        dispatch(showHideWarning({
          isVisible: false,
          amount: 0,
          onClickString: '() => {}',
          functionContext: null,
        }));

        logoutCrowdin();
      })
      .catch((error) => {
        sentryLogger.exceptionWithScope(error, { user, url });
      });
  };
}

/**
 * @param {string|number} id
 * @param {History | any} history
 * @param {string?} who
 * @param {string?} name
 * @param {number|null} [clientId=null]
 * @param {boolean} isCompany
 */
export function impersonate(id, history, who, name, clientId = null, isCompany = false) {
  return (dispatch) => {
    dispatch(getImpersonateData(id, isCompany))
      .then(() => {
        logoutCrowdin();
        dispatch(setImpersonate(who));

        localStorage.setItem('impersonate_id', id);
        localStorage.setItem('impersonate_is_company', isCompany);
        localStorage.setItem('impersonate_name', name);
        localStorage.setItem('type', who);

        // clear dashboard sorting and view params
        localStorage.removeItem(CONCEPT_BRIEFINGS_PAGINATION_KEY);
        localStorage.removeItem(ACTIVE_PROJECT_PAGINATION_KEY);
        localStorage.removeItem(FINISHED_PROJECT_PAGINATION_KEY);
        localStorage.removeItem(ACTIVE_PROJECT_SORTING_KEY);
        localStorage.removeItem(FINISHED_PROJECT_SORTING_KEY);

        if (clientId) {
          localStorage.setItem('client_id', clientId);
        }
        if (who === USER_ROLES.WRITER || who === USER_ROLES.DESIGNER) {
          dispatch(getCompleteProjects(id));
          dispatch(getProjectTypes());
        }
        history.push('/dashboard/projects');
      });
  };
}

export function unimpersonate(who, history) {
  return (dispatch) => {
    const url = `/unimpersonate`;

    client.post(url)
      .then(data => {
        if (!data) {
          throw new Error('UnImpersonate Error');
        }
        logoutCrowdin();

        dispatch(getProjectTypes());
      });

    dispatch({
      type: types.UNIMPERSONATE,
    });
    localStorage.removeItem('impersonate_id');
    localStorage.removeItem('impersonate_is_company');
    localStorage.removeItem('impersonate_name');
    localStorage.removeItem('type');
    localStorage.removeItem('client_id');
    localStorage.removeItem('show_freelancer_warning');
    localStorage.removeItem('uncomplete_umount');

    // clear dashboard sorting and view params
    localStorage.removeItem(ACTIVE_PROJECT_PAGINATION_KEY);
    localStorage.removeItem(FINISHED_PROJECT_PAGINATION_KEY);
    localStorage.removeItem(ACTIVE_PROJECT_SORTING_KEY);
    localStorage.removeItem(FINISHED_PROJECT_SORTING_KEY);

    dispatch(showHideWarning({
      isVisible: false,
      amount: 0,
      onClickString: '() => {}',
      functionContext: null,
    }));

    const regArray = [
      /\bclient-settings\b/,
      /\badd-writer-to-team\b/,
      /\badd-designer-to-team\b/,
      /\bcopywriter-settings\b/,
      /\bdesigner-settings\b/,
    ];

    if (shouldRedirect(regArray)) {
      if (who === USER_ROLES.CLIENT) {
        history.push('/dashboard/clients');
      } else {
        history.push('/dashboard/freelancers/list/copy');
      }
    } else {
      window.location.reload();
    }
  };
}

export function resetPassword(mail, history) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = `/sendResetLinkEmail`;

    return quest.post(url, { email: mail })
      .then(data => {
        if (!data) {
          throw new Error('The selected email is invalid.');
        }
        if (data.data.success) {
          dispatch({
            type: types.RESET_PASSWORD_SUCCESS,
          });
          history.push('/en/reset-password-confirmation');
        }
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });

        dispatch({
          type: types.RESET_PASSWORD_FAILURE,
          payload: error,
        });
      });
  };
}

export function closePopup() {
  return {
    type: types.CLOSE_POPUP,
  };
}

export function newPassword(data, history) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = '/resetPassword';

    return quest.post(url, data)
      .then(response => {
        if (!response.data.success) {
          throw new Error(response.data.result);
        }
        dispatch({
          type: types.REQUEST_NEW_PASSWORD_SUCCESS,
        });
        history.push('/login');
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });

        dispatch({
          type: types.REQUEST_NEW_PASSWORD_FAILURE,
          payload: error,
        });
      });
  };
}

export function hideUpdateBar() {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_HIDE_PLATFORM_UPDATE,
      payload: false,
    });

    dispatch({
      type: types.SET_PLATFORM_UPDATE_TEXT,
      payload: '',
    });

    sessionStorage.setItem('hideUpdateBar', 'true');
  };
}

export function updateAlertInfo(id, active, text, readMoreLink) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = `/alerts/${id}`;
    const data = {
      type: 'alert-info',
      active: active ? '1' : '0',
      text,
      read_more: readMoreLink,
    };

    if (!active) {
      localStorage.removeItem('info_alert_id');
      localStorage.removeItem('info_alert_text');
      localStorage.removeItem('info_alert_link');
      localStorage.removeItem('info_alert_active');
    }

    return client.put(url, data)
      .then(response => {
        if (response.data.success) {
          const infoAlert = response.data.result;
          if (infoAlert) {
            localStorage.setItem('info_alert_id', infoAlert.id);
            localStorage.setItem('info_alert_text', infoAlert.text);
            localStorage.setItem('info_alert_link', infoAlert.read_more);
            infoAlert.active === '1' && localStorage.setItem('info_alert_active', infoAlert.active);

            dispatch({
              type: types.SHOW_HIDE_PLATFORM_UPDATE,
              payload: infoAlert.active,
            });

            dispatch({
              type: types.SET_PLATFORM_UPDATE_TEXT,
              payload: infoAlert.text,
            });

            dispatch({
              type: types.SET_PLATFORM_UPDATE_LINK,
              payload: infoAlert.read_more,
            });
          }
        }
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });
      });
  };
}

export function verifyEmail(token) {
  return () => {
    const url = `/user/verify-email/${token}`;

    return quest(url)
      .then(data => data)
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { url });
        throw error.data;
      });
  };
}

export function sendResetPassword(email) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = '/sendResetLinkEmail';

    const requestData = {
      email,
    };

    return client.post(url, requestData)
      .then(() => {
        dispatch({
          type: types.SEND_RESET_PASSWORD_LINK,
        });
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });
        throw error.data;
      });
  };
}
